import { configureStore } from '@reduxjs/toolkit';
import  userReducer  from './reducers/userReducer';
import drawerReducer from './reducers/drawerReducer';
import themeReducer from './reducers/themeReducer';

const store = configureStore({
    reducer: {
      user: userReducer,
      drawer: drawerReducer,
      theme: themeReducer,
    },
});

export default store;