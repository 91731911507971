export const lightTheme = {
    colors: {
        primary: '#ff5733',
        secondary: '#4caf50',
        background: '#f0f0f0',
        backgroundDrawer: '#c0c0c0',
        backgroundHeader: '#d8d8d8',
        text: '#222222',
        textDrawer: '#111111',
        buttonText: '#ffffff',
        tableBackgroundOdd: '#e0e0e0',
        tableBackgroundEven: '#c7c7c7',
        error: '#ff0000',
    },
    fontSizes: {
        small: '12px',
        medium: '16px',
        large: '22px',
    },
};

export const darkTheme = {
    colors: {
      primary: '#ff5733',
      secondary: '#4caf50',
      background: '#222222',
      backgroundDrawer: '#444444',
      backgroundHeader: '#111111',
      text: '#f0f0f0',
      textDrawer: '#fcfcfc',
      buttonText: '#ffffff',
      tableBackgroundOdd: '#333333',
      tableBackgroundEven: '#444444',
      error: '#ff0000',
    },
    fontSizes: {
      small: '12px',
      medium: '16px',
      large: '22px',
    },
  };