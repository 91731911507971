import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routesConfig from './config/routesConfig';
import { ThemeProvider } from './theme/ThemeContext';
import Header from './drawer/Header';
import Drawer from './drawer/Drawer';

const AppContent = () => {
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const currentRoute = routesConfig.find(route => route.path === location.pathname);
  const showDrawer = currentRoute ? currentRoute.showDrawer : false;

  return (
    <div style={{ display: 'flex' }}>
      {showDrawer && <Drawer />}
      <div style={{ flexGrow: 1 }}>
        {showDrawer && <Header />}
        <div>
          <Routes>
            {routesConfig.map(({ path, element, isProtected }) => (
              <Route
                key={path}
                path={path}
                element={
                  isProtected && !isAuthenticated ? /*(*/
                    <Navigate to="/" replace />
                  //) : (
                    : element
                  //)
                }
              />
            ))}
          </Routes>
        </div>
      </div>
    </div>
  );
};

const App = () => (
  <ThemeProvider>
    <Router>
      <AppContent />
    </Router>
  </ThemeProvider>
);

export default App;