import Login from '../login/Login';
import HomePage from '../menu/HomePage';

const routesConfig = [
    {
        path: '/',
        element: <Login />,
        isProtected: false,
        showDrawer: false,
    },
    {
        path: '/home',
        element: <HomePage />,
        isProtected: true,
        showDrawer: true,
    },
];

export default routesConfig;