import { TOGGLE_THEME } from '../actions/themeActions';
import { lightTheme, darkTheme } from '../../theme/theme';

const initialState = {
    theme: lightTheme,
    isDarkMode: false,
}

const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_THEME:
            return {
                ...state,
                theme: state.isDarkMode ? lightTheme : darkTheme,
                isDarkMode: !state.isDarkMode,
            };
        default:
            return state;
    }
};

export default themeReducer;