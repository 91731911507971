import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Drawer.css';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer } from '../redux/actions/drawerActions';

import { MdOutlineMenu } from "react-icons/md";
import { FaHome } from 'react-icons/fa';
import { TbListDetails } from "react-icons/tb";
import { MdMarkEmailRead } from "react-icons/md";

const Drawer = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.drawer.isOpen);
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme.theme);

  const handleToogleDrawer = () => {
    dispatch(toggleDrawer());
  };

  return (
    <div 
      className={`drawer ${isOpen ? 'open' : 'collapsed'}`}
      style={{ backgroundColor: theme.colors.backgroundDrawer, color: theme.colors.textDrawer }}
    >
      <button onClick={handleToogleDrawer} className="toggle-button" style={{ color: theme.colors.textDrawer }}>
        <MdOutlineMenu className="icon" />
      </button>
      <ul className="drawer-menu">
        <li onClick={() => navigate('/home')}>
          <FaHome className="icon" />
          {isOpen && <span className="label">Home</span>}
        </li>
        <li onClick={() => navigate('/details')}>
          <TbListDetails className="icon" />
          {isOpen && <span className="label">Details</span>}
        </li>
        <li onClick={() => navigate('/email')}>
          <MdMarkEmailRead className="icon" />
          {isOpen && <span className="label">Send email</span>}
        </li>
      </ul>
    </div>
  );
};

export default Drawer;