import { LOGIN_USER, LOGOUT_USER } from '../actions/userActions';

const initialState = {
    isAuthenticated: false,
    username: null,
    token: null,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                isAuthenticated: true,
                username: action.payload.username,
                token: action.payload.token,
            };
        case LOGOUT_USER:
            return initialState;
        default:
            return state;
    }
};

export default userReducer;