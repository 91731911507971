import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { loginUser } from '../redux/actions/userActions';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    try {
      const response = await axios.post(
        'https://react-test.soliddop.net/login.php',
        { username, password },
        { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } }
      );

      const data = response.data;

      if (data.status === 'success') {
        dispatch(loginUser({ username: data.username, token: data.token }));
        navigate('/home');
      } else {
        setMessage(data.message || 'Error occurred during login');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };
  console.log("PROCESS ENV", process.env.PUBLIC_URL);

  return (
    <div style={styles.main_container}>
      <div style={styles.container}>
         {process.env.PUBLIC_URL ? (
        <img 
          src={`${process.env.PUBLIC_URL}/logos/media-box-logo.png`} 
          alt="Logo" 
          style={styles.logo} 
        />
      ) : (
        <p style={{ color: 'red' }}>PUBLIC_URL is not defined</p>
      )}
        <form onSubmit={handleSubmit} style={styles.form}>
          <h2 style={styles.header}>Login</h2>

          {message && <div style={styles.message}>{message}</div>}
          <div style={styles.inputGroup}>
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              style={styles.input}
            />
          </div>

          <div style={styles.inputGroup}>
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={styles.input}
            />
          </div>

          <div style={styles.buttonContainer}>     
             <button type="submit" style={styles.button}>Login</button>
            </div>
        </form>
      </div>
    </div>
  );
};

const styles = {
  main_container: {
    backgroundColor: "#FF6565",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  container: {
    padding: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '10px',
    boxShadow: `
      0 10px 15px rgba(0, 0, 0, 0.15),
      0 20px 30px rgba(0, 0, 0, 0.15),
      0 40px 50px rgba(0, 0, 0, 0.1),
      0 50px 75px rgba(0, 0, 0, 0.05)
    `,
  },
  buttonContainer: {
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    display: 'inherit',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  form: {
    padding: '10px',
    borderRadius: '5px',
    width: '100%',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  input: {
    width: '100%',
    padding: '12px', 
    borderRadius: '4px',
    border: '1px solid #ccc',
    boxSizing: 'border-box', 
  },
  button: {
    width: '100%',
    padding: '12px',
    backgroundColor: '#333',
    color: 'white',
    fontWeight: 'bold',
    border: '1px solid #ccc', 
    borderRadius: '4px', 
    cursor: 'pointer',
  },
  message: {
    color: 'red',
    fontWeight: 'bold',
    marginBottom: '15px',
  },
};

export default Login;