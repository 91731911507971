import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../redux/actions/userActions';
import { toggleTheme } from '../redux/actions/themeActions';

const Header = () => {
  const dispatch = useDispatch();
  const { theme,isDarkMode } = useSelector((state) => state.theme);
  const [profileMenu, setProfileMenuOpen] = useState(false);

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const toggleProfileMenu = () => {
    setProfileMenuOpen((prev) => !prev);
  };
  
  return (
    <div style={{ ...styles.header, backgroundColor: theme.colors.backgroundHeader }}>
      <button 
        onClick={() => dispatch(toggleTheme())} 
        style={{ ...styles.themeButton, color: theme.colors.text, fontSize: theme.fontSizes.small }}>
        {isDarkMode ? 'Light' : 'Dark'} Mode
      </button>
      <div style={styles.profileContainer} onClick={toggleProfileMenu}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/photo_profile_200.jpg`}
          alt="Profile"
          style={styles.profilePhoto}
        />
        {profileMenu && (
          <ul style={{ ...styles.menu, backgroundColor: theme.colors.backgroundDrawer }}>
            <li style={{ ...styles.menuItem, color: theme.colors.text, borderColor: theme.colors.text,  fontSize: theme.fontSizes.medium }}>Edit Profile</li>
            <li style={{ ...styles.menuItem, color: theme.colors.text, borderColor: theme.colors.text,  fontSize: theme.fontSizes.medium }} onClick={handleLogout}>
              Logout
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

const styles = {
  header: {
    height: '10vh',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    justifyContent: 'flex-end',
  },
  themeButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    paddingRight: '30px',
  },
  profileContainer: {
    position: 'relative',
    cursor: 'pointer',
  },
  profilePhoto: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  menu: {
    position: 'absolute',
    top: '46px',
    right: 0,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    listStyle: 'none',
    padding: '0',
    borderRadius: '5px',
    width: '110px',
  },
  menuItem: {
    padding: '8px 12px',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderWidth: '0 0 1px 0',
  },
};

export default Header;