import React, { useEffect, useState }  from 'react';
import './css/HomePage.css';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { AiOutlineFolderView } from "react-icons/ai";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const HomePage = () => {
  const { theme } = useSelector((state) => state.theme);
  const [ journalists, setJournalists ] = useState([]);
  const [ currentPage, setCurrentPage ] = useState(1);
  const itemsPerPage = 50;

  useEffect(() => {
    const fetchJournalists = async () => {
      try {
        const response = await axios.get('https://react-test.soliddop.net/getjournalists.php');
        setJournalists(response.data);
      } catch (error) {
        console.error('Error fetching journalists:', error);
      }
    };
    fetchJournalists();
  }, []);

  const totalPages = Math.ceil(journalists.length / itemsPerPage);

  const currentData = journalists.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="view" style={{ backgroundColor: theme.colors.background }}>
      <p className="view_paragraph" style={{ color: theme.colors.text }}>
        Λίστα δημοσιογράφων
      </p>
      <table>
        <thead>
          <tr>
            <th style={{ backgroundColor: theme.colors.primary}}>ΟΝΟΜΑΤΕΠΩΝΥΜΟ</th>
            <th style={{ backgroundColor: theme.colors.primary}}>ΘΕΣΗ</th>
            <th style={{ backgroundColor: theme.colors.primary}}>ΟΝΟΜΑ ΜΕΣΟΥ / ΟΜΙΛΟΣ</th>
            <th style={{ backgroundColor: theme.colors.primary}}>ΡΕΠΟΡΤΑΖ</th>
            <th style={{ backgroundColor: theme.colors.primary}}>CONTACT</th>
            <th style={{ backgroundColor: theme.colors.primary}}>ΠΕΡΙΟΧΗ</th>
            <th style={{ backgroundColor: theme.colors.primary}}>ΕΝΕΡΓΕΙΕΣ</th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((journalist, index) => (
            <tr key={index}
              style={{ backgroundColor: index % 2 === 0 ? theme.colors.tableBackgroundOdd : theme.colors.tableBackgroundEven }}
            >
              <td style={{ color: theme.colors.text, fontSize: theme.fontSizes.small }}>{journalist.fullname}</td>
              <td style={{ color: theme.colors.text, fontSize: theme.fontSizes.small }}>{journalist.position}</td>
              <td style={{ color: theme.colors.text, fontSize: theme.fontSizes.small }}>{journalist.medias}</td>
              <td style={{ color: theme.colors.text, fontSize: theme.fontSizes.small }}>{journalist.report}</td>
              <td style={{ color: theme.colors.text, fontSize: theme.fontSizes.small }}>{journalist.contact}</td>
              <td style={{ color: theme.colors.text, fontSize: theme.fontSizes.small }}>{journalist.county}</td>
              <td style={{ color: theme.colors.text }}>
                <button className="actionButton">
                  <AiOutlineFolderView className="icon_view" />
                </button>
                <button className="actionButton">
                  <FaRegEdit className="icon_edit" />
                </button>
                <button className="actionButton">
                  <MdDelete className="icon_delete" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button className="pageButton"
            key={index + 1}
            style={{
              backgroundColor: currentPage === index + 1 ? theme.colors.primary : theme.colors.background,
              color: currentPage === index + 1 ? theme.colors.buttonText : theme.colors.text,
            }}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default HomePage;